<template>
  <!-- 投诉具体原因Popover -->
  <el-dialog
    title=""
    :visible.sync="$store.state.complaint.complaintResonPopoverVisible"
    custom-class="complaint-reason-popover"
    :before-close="beforeClose"
    modal-append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    center
  >
    <!-- loading -->
    <div v-if="loading" class="no-data">
      <i
        class="el-icon-loading"
        style="font-size: 18px; margin-bottom: 10px"
      ></i>
      <div>{{ $t("loading") }}...</div>
    </div>
    <!-- 资源不存在 -->
    <div v-if="showNoData" class="no-data">
      <img src="../../../assets/images/class/no-data-img.png" />
      <div>
        {{
          type == "-200"
            ? $t("noPost")
            : type == "-900"
            ? $t("nogrop")
            : $t("noData")
        }}
      </div>
    </div>
    <div v-else>
      <!-- 帖子 -->
      <div v-if="type == -200 && details">
        <div class="delete-reason-header">
          {{ $t("complaintReason") }}：{{ details.reportRemarkDescription }}
        </div>
        <div class="delete-reason-body">
          <div class="delete-reason-user">
            <div class="user-head">
              <img
                :src="details.profilePhoto"
                style="width: 50px; height: 50px;"
                alt=""
              />
            </div>
            <div>
              <span v-if="details.vipStatus" class="xvipName">
                <span
                  class="vipDisplayName"
                  @click.stop="goPersonalCenter(details.providerId)"
                  >{{ details.name }}</span
                >
                <el-tooltip
                  effect="dark"
                  :content="$t('paidMember')"
                  placement="bottom"
                  popper-class="me-tooltip-effect"
                >
                  <el-image
                    class="vipIcon"
                    :src="require('@/assets/images/pay/masonry.png')"
                    fit="fill"
                    @click.stop="handleClickVip(details.providerId)"
                  >
                  </el-image>
                </el-tooltip>
              </span>
              <template>
                <div
                  class="user-name"
                  @click.stop="goPersonalCenter(details.providerId)"
                >
                  {{ details.name }}
                </div>
              </template>
              <div class="user-time">
                {{ details.publishTime }}
              </div>
            </div>
          </div>
          <h3 class="delete-reason-title">
            {{ details.iCanDo }}
          </h3>

          <div v-if="details.detail">
            <div
              v-for="(item, index) in JSON.parse(details.detail)"
              :key="index"
            >
              <div v-if="item.key === 'web'" class="">
                <a class="details_web" target="_black" :href="item.content.url">
                  <div class="web_img">
                    <img :src="item.content.image" alt="" />
                  </div>
                  <div class="web_info">
                    <div class="web-title">
                      {{ item.content.title }}
                    </div>
                    <div class="web-cont">
                      {{ item.content.content }}
                    </div>
                  </div>
                </a>
              </div>
              <div v-if="item.key === 'text'" v-html="item.content"></div>
            </div>
          </div>
          <div v-else class="delete-reason-content">
            {{ details.detailContent }}
          </div>
          <div
            class="delete-reason-content"
            v-if="!details.detailContent"
            v-html="details.richDetail"
          ></div>
          <!-- 标签 -->
          <div class="delete-tags" v-if="details.tags">
            <div
              class="tag-item"
              v-for="item in details.tags.split(',')"
              :key="item"
            >
              <span class="iconfont_Me icon-tag2 labelIcon"></span>{{ item }}
            </div>
          </div>
          <!-- 图片 -->
          <div class="delete-reason-imgs" v-if="details.attachments.length > 0">
            <img
              v-for="item in details.attachments"
              :src="item.path"
              :key="item.id"
              alt=""
            />
            <video
              v-if="details.attachments && details.attachments[0].type == 1"
              :src="details.attachments[0].path"
              controls
            ></video>
          </div>
          <FilePreview
            v-if="
              details.attachments.length > 0 &&
                details.attachments[0].type === 4 &&
                details.attachments[0].path !== ''
            "
            :fileInfo="{ ...details.attachments[0], progress: 100 }"
          ></FilePreview>
        </div>

        <div
          class="operation"
          v-if="
            details.reportManagerStatus == 0 &&
              details.isDelete != 3 &&
              details.isDelete != 2
          "
        >
          <el-button @click="handleDelet">{{ $t("okDelete") }}</el-button>
          <el-button @click="handleIgnore">{{ $t("neglect") }}</el-button>
        </div>
        <div v-else class="operation">
          <div class="processed">{{ $t("processed") }}</div>
        </div>
      </div>
      <!-- 群 -->
      <div v-if="type == -900 && groupInfo">
        <div class="delete-reason-header">
          {{ $t("complaintReason") }}：{{ groupInfo.reportRemarkDescription }}
        </div>
        <div class="delete-reason-body">
          <div class="delete-reason-user">
            <div class="user-head groupInfo">
              <img
                :src="groupInfo.group_header"
                style="width: 50px; height: 50px"
                alt=""
              />
              <span
                class="iconfont_Me iconStyle icon-open-fill"
                style="color: rgb(51, 51, 51)"
              ></span>
            </div>
            <div>
              <div class="user-name">
                {{ groupInfo.group_name }}
              </div>
              <div class="user-time">
                {{ groupInfo.join_time }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="operation"
          v-if="
            groupInfo.reportManagerStatus == 0 &&
              groupInfo.is_delete != 3 &&
              groupInfo.is_delete != 2
          "
        >
          <el-button @click="handleDelet">{{ $t("okDelete") }}</el-button>
          <el-button @click="handleIgnore">{{ $t("neglect") }}</el-button>
        </div>
        <div v-else class="operation">
          <div class="processed">{{ $t("processed") }}</div>
        </div>
      </div>
      <!-- 投诉用户 -->
      <div v-if="type == -100 && userInformation">
        <div class="delete-reason-header">
          {{ $t("complaintReason") }}：{{
            userInformation.report.reportRemarkDescription
          }}
        </div>
        <div class="delete-reason-body">
          <div class="delete-reason-user">
            <div class="user-head groupInfo">
              <img
                :src="userInformation.profile_photo"
                style="width: 50px; height: 50px"
                alt=""
              />
            </div>
            <div>
              <div
                class="user-name"
                @click.stop="
                  goPersonalCenter(
                    userInformation.provider_id || userInformation.providerId
                  )
                "
              >
                {{ userInformation.name }}
              </div>
              <div class="user-time">ID:{{ userInformation.uc_id }}</div>
            </div>
          </div>
          <!-- 标签 -->
          <div
            class="delete-tags"
            style="margin-top: 10px"
            v-if="userInformation.skills"
          >
            <div
              class="tag-item"
              v-for="item in userInformation.skills.split(',')"
              :key="item"
            >
              <span class="iconfont_Me icon-tag2 labelIcon"></span>{{ item }}
            </div>
          </div>
          <!-- rich -->
          <div class="user-rich" v-html="userInformation.rich_intro"></div>
        </div>
        <div
          class="operation"
          v-if="
            userInformation.report.reportManagerStatus == 0 &&
              userInformation.report.isDelete != 3 &&
              userInformation.report.isDelete != 2
          "
        >
          <el-button @click="handleDelet">{{ $t("okDelete") }}</el-button>
          <el-button @click="handleIgnore">{{ $t("neglect") }}</el-button>
        </div>
        <div v-else class="operation">
          <div class="processed">{{ $t("processed") }}</div>
        </div>
      </div>
      <!-- 评论 -->
      <div
        v-if="
          (type == -901 || type == -201 || type == -101) &&
            groupReplyInfodetails
        "
      >
        <div class="delete-reason-header">
          {{ $t("complaintReason") }}：{{
            groupReplyInfodetails.reportRemarkDescription
          }}
        </div>
        <div class="delete-reason-body">
          <div class="delete-reason-user" v-if="complainedUser">
            <div class="user-head groupInfo">
              <img
                :src="complainedUser.profile_photo"
                style="width: 50px; height: 50px"
                alt=""
              />
            </div>
            <div>
              <div
                class="user-name"
                @click.stop="goPersonalCenter(complainedUser.provider_id)"
              >
                {{ complainedUser.name }}
              </div>
              <!-- {{groupReplyInfodetails}} -->
              <div class="user-time">
                {{
                  groupReplyInfodetails.join_time ||
                    groupReplyInfodetails.reviewDatetime
                }}
              </div>
            </div>
          </div>
          <div
            style="margin-top: 10px; white-space: pre-wrap;line-height: 24px;max-height: 168px;overflow-x: hidden;overflow-y: auto;"
            v-html="
              $emoReplaceToImage(hyperlinksCheck(groupReplyInfodetails.content))
            "
          ></div>
          <div
            :style="{ height: '100px', 'margin-top': '10px' }"
            v-if="
              groupReplyInfodetails.imgUrl && groupReplyInfodetails.imgUrl != ''
            "
          >
            <el-image
              :src="groupReplyInfodetails.imgUrl"
              fit="cover"
              style="width: 100px; height: 100%"
            ></el-image>
          </div>
        </div>
        <div
          class="operation"
          v-if="
            groupReplyInfodetails.reportManagerStatus == 0 &&
              groupReplyInfodetails.isDelete != 3 &&
              groupReplyInfodetails.isDelete != 2
          "
        >
          <el-button @click="handleDelet">{{ $t("okDelete") }}</el-button>
          <el-button @click="handleIgnore">{{ $t("neglect") }}</el-button>
        </div>
        <div v-else class="operation">
          <div class="processed">{{ $t("processed") }}</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getPostInformation } from "@/api/newVersion/postContentDetails";
import { getGroupDetail } from "@/api/newVersion/groupCenter";
import { getProfileInfoData, replyInfo } from "@/api/rest";
import { reportManager } from "@/api/rest";
import FilePreview from "../showFileItem";
export default {
  components: {
    FilePreview,
  },
  data() {
    return {
      details: null,
      type: null,
      userInfo: null,
      groupInfo: null,
      userInformation: null,
      groupReplyInfodetails: null,
      complainedUser: null, //被投诉的用户信息
      baseImgUrl: "https://pic.melinked.com",
      showNoData: false,
      loading: true,
    };
  },
  props: {},
  watch: {
    "$store.state.complaint.complaintReportRecordId": {
      handler(val, oldVal) {
        this.getDetails(val);
      },
      immediate: true,
    },

    "$store.state.complaint.updateState": {
      handler() {
        this.getDetails(this.$store.state.complaint.complaintReportRecordId);
      },
      immediate: true,
    },
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo;
  },
  methods: {
    goPersonalCenter(id) {
      if (id) {
        this.routeJump({
          name: "personalCenter",
          params: { id: id },
        });
      }
    },
    async getDetails(val) {
      this.showNoData = false;
      let complaintResonId = this.$store.state.complaint.complaintResonId;
      let type = this.$store.state.complaint.complaintResonType;
      this.type = type;
      let result;
      //  (-100:用户, -200：帖子， -900：群 -101用户评论， -201：帖子评论， -901：群评论)
      switch (type) {
        case -100: //投訴用戶
          let params = {
            providerId: complaintResonId,
            userId: complaintResonId,
            reportRecordId: val,
            language: 1,
          };
          result = await getProfileInfoData(params);
          this.userInformation = result.data[0];
          this.loading = false;
          if (this.userInformation.profile_photo) {
            let reg = /(http|https):\/\/\S*/;
            let isA = reg.test(this.userInformation.profile_photo);
            if (!isA) {
              this.userInformation.profile_photo =
                this.baseImgUrl + "/" + this.userInformation.profile_photo;
            }
          }
          break;
        case -200: //投诉帖子
          result = await getPostInformation(complaintResonId, val);
          if (result.code == 200) {
            this.details = result.data.data;
            this.loading = false;
            if (this.details.profilePhoto) {
              let reg = /(http|https):\/\/\S*/;
              let isA = reg.test(this.details.profilePhoto);
              if (!isA) {
                this.details.profilePhoto =
                  this.baseImgUrl + "/" + this.details.profilePhoto;
              }
            }
            if (this.details.detail) {
              let isJSON = this.isJSON(this.details.detail);
              if (
                isJSON &&
                this.details.detail != "[]" &&
                this.details.detail != "{}"
              ) {
                this.details.detailContent = JSON.parse(
                  this.details.detail
                )[0].content;
              } else {
                if (
                  this.details.detail == "[]" ||
                  this.details.detail == "{}"
                ) {
                  this.details.detailContent = "";
                } else {
                  this.details.detailContent = this.details.detail;
                }
              }
            }
          } else {
            this.details = null;
            this.loading = false;
            this.showNoData = true;
          }

          break;
        case -900: //投诉群
          result = await this.getGroupInfoById(complaintResonId, val);
          break;
        case -101: //投诉用户评论
          result = await replyInfo({
            operation: 3,
            replyId: complaintResonId,
            reportRecordId: val,
            violationRecordId: val,
            language: 1,
          });
          this.groupReplyInfodetails = result.data.data;
          if (this.groupReplyInfodetails.content) {
            this.groupReplyInfodetails.content = this.reconvert(
              this.groupReplyInfodetails.content
            );
          }
          let userInfo = await getProfileInfoData({
            providerId: this.groupReplyInfodetails.reviewerId,
            userId: this.groupReplyInfodetails.reviewerId,
            reportRecordId: val,
            language: 1,
          });
          this.loading = false;
          this.complainedUser = userInfo.data[0] || null;
          break;
        case -201: //投诉帖子评论
          result = await replyInfo({
            operation: 1,
            replyId: complaintResonId,
            reportRecordId: val,
            violationRecordId: val,
            language: 1,
          });
          this.groupReplyInfodetails = result.data.data;
          if (this.groupReplyInfodetails.content) {
            this.groupReplyInfodetails.content = this.reconvert(
              this.groupReplyInfodetails.content
            );
          }
          let userInfo2 = await getProfileInfoData({
            providerId: this.groupReplyInfodetails.reviewerId,
            userId: this.groupReplyInfodetails.reviewerId,
            reportRecordId: val,
            language: 1,
          });
          this.loading = false;
          this.complainedUser = userInfo2.data[0] || null;
          break;
        case -901: //投诉群评论
          result = await replyInfo({
            operation: 4,
            replyId: complaintResonId,
            reportRecordId: val,
            violationRecordId: val,
            language: 1,
          });
          this.groupReplyInfodetails = result.data.data;
          if (this.groupReplyInfodetails.content) {
            this.groupReplyInfodetails.content = this.reconvert(
              this.groupReplyInfodetails.content
            );
          }
          let userInfo3 = await getProfileInfoData({
            providerId: this.groupReplyInfodetails.reviewerId,
            userId: this.groupReplyInfodetails.reviewerId,
            reportRecordId: val,
            language: 1,
          });
          this.loading = false;
          this.complainedUser = userInfo3.data[0] || null;
          break;
        default:
          break;
      }
    },
    // 根据ID获取群组的详细信息
    async getGroupInfoById(groupId, val) {
      if (groupId) {
        let params = {
          groupId: groupId,
          userId: this.$store.getters.userInfo.id || "",
          reportRecordId: val,
        };
        let result = await getGroupDetail(params);
        if (result.code == 1) {
          this.loading = false;
          this.groupInfo = result.data[0];
          if (this.groupInfo.group_header) {
            let reg = /(http|https):\/\/\S*/;
            let isA = reg.test(this.groupInfo.group_header);
            if (!isA) {
              this.groupInfo.group_header =
                this.baseImgUrl + this.groupInfo.group_header;
            }
          }
        } else {
          if (result.message) {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
          this.loading = false;
          this.showNoData = true;
        }
      }
    },
    //取消操作
    dialogVisible() {
      this.$store.commit("setComplaintResonPopoverVisible", false);
    },
    beforeClose() {
      this.dialogVisible();
    },
    handleDelet() {
      this.$store.commit("setDeleteDialogVisible", true);
    },
    async handleIgnore() {
      let complaintReportRecordId = this.$store.state.complaint
        .complaintReportRecordId;
      let reportParams = {
        managerStatus: 2,
        reportRecordId: complaintReportRecordId,
      };
      let result = await reportManager(reportParams);
      if (result && result.code == 200) {
        this.$message({
          type: "success",
          message: "忽略成功",
        });

        // 刷新一下数据
        this.getDetails(this.$store.state.complaint.complaintReportRecordId);
        this.$store.commit("setUpdateState", Date.now());
        this.$store.commit("setDeleteDialogVisible", false);
        this.$store.commit("setComplaintResonPopoverVisible", false);
        this.$store.commit("setReloadPage", false);
        this.$nextTick((e) => {
          this.$store.commit("setReloadPage", true);
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
        // 刷新一下数据
        this.getDetails(this.$store.state.complaint.complaintReportRecordId);
        this.$store.commit("setDeleteDialogVisible", false);
        this.$store.commit("setComplaintResonPopoverVisible", false);
        this.$store.commit("setReloadPage", false);
        this.$nextTick((e) => {
          this.$store.commit("setReloadPage", true);
        });
      }
    },
    reconvert(str) {
      str = str.replace(/(\\u)(\w{1,4})/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%5Cu)(\w{1,4})/g, "$2"), 16)
        );
      });
      str = str.replace(/(&#x)(\w{1,4});/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%26%23x)(\w{1,4})(%3B)/g, "$2"), 16)
        );
      });
      str = str.replace(/(&#)(\d{1,6});/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%26%23)(\d{1,6})(%3B)/g, "$2"))
        );
      });
      return str;
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }
      // console.log("It is not a string!");
    },
  },
};
</script>

<style lang="scss">
// @media screen and (min-width: 100px) {
//   .delete-reason-dialog {
//     width: 70% !important;
//   }
// }

// @media screen and (min-width: 768px) {
//   .delete-reason-dialog {
//     width: 70% !important;
//   }
// }
// @media screen and (min-width: 992px) {
//   .delete-reason-dialog {
//     width: 50% !important;
//   }
// }
// @media screen and (min-width: 1200px) {
//   .delete-reason-dialog {
//     width: 30% !important;
//   }
// }

.complaint-reason-popover {
  // width: 600px !important;
  max-height: 600px;
  overflow: hidden;

  /*修改滚动条样式*/
  div::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    /**/
  }

  div::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }

  div::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }

  div::-webkit-scrollbar-thumb:hover {
    background: #333;
  }

  div::-webkit-scrollbar-corner {
    background: #179a16;
  }

  // max-height: 60%;
  user-select: none;

  .el-dialog__header {
    background: #f0f0f0;
  }

  .el-dialog--center,
  .el-dialog__body {
    padding: 0 !important;
    border-radius: 8px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.06);
    // height: 724px;
  }

  .delete-reason-header {
    height: 42px;
    background: #f0f0f0;
    padding: 0 30px;
    font-size: 15px;
    font-weight: 700;
    color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete-reason-body {
    overflow: auto;
    max-height: 400px;
    padding: 30px;

    .delete-reason-user {
      display: flex;
      align-items: center;

      .user-head {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;

        &.groupInfo {
          margin-right: 15px;
        }

        span {
          margin-left: 4px;
        }

        image {
          width: 100%;
          height: 100%;
        }
      }

      .user-name {
        font-weight: 700;
        font-size: 14px;
        color: #333;
        margin-bottom: 4px;
      }
      .xvipName {
        max-width: 130px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        .vipDisplayName {
          max-width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .vipIcon {
          width: 17px;
          height: 14px;
          margin-left: 6px;
        }
      }

      .user-time {
        font-size: 12px;
        color: #8f8f8f;
      }
    }

    .user-rich {
      margin-top: 20px;

      img {
        max-width: 100%;
      }
    }

    .delete-reason-content {
      margin-bottom: 10px;

      img {
        max-width: 100%;
      }
    }

    .delete-tags {
      .tag-item {
        width: fit-content;
        padding: 0 10px;
        height: 30px;
        display: flex;
        align-items: center;
        background: #f2fcf8;
        border-radius: 15px;
        font-size: 12px;

        .labelIcon {
          font-size: 18px;
          color: #3c6;
          margin-right: 4px;
        }
      }
    }

    .delete-reason-imgs {
      img {
        max-width: 100%;
        height: 100%;
        border-radius: 3px;
      }
    }

    // 附件部分
    .fileContent-wrap {
      height: 85px;
      padding-left: 25px;
      background: #f7f7f7;
    }
  }

  .operation {
    display: flex;
    justify-content: center;
    padding: 40px 0;

    .el-button {
      width: 160px;
      height: 50px;
      margin-right: 20px;
      background: #f0f0f0;
      border-radius: 8px;
      color: #1a1a1a;
      border: none;
    }

    .processed {
      width: 150px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      background: #ccc;
      border-radius: 8px;
    }
  }
}

.no-data {
  width: 100%;
  height: 300px;
  border-top: 30px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  img {
    margin-bottom: 10px;
  }
}

.details_web {
  background: rgb(247, 247, 247);
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 20px 24px;
  margin-bottom: 4px;
  .web_img {
    width: 70px;
    height: 70px;
    margin-right: 10px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .web_info {
    .web-title {
      font-size: 14px;
      font-weight: bold;
      color: rgb(55, 55, 55);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 10px;
    }

    .web-cont {
      width: 100%;
      text-align: left;
      font-size: 12px;
      color: rgb(55, 55, 55);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
