var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible: _vm.$store.state.complaint.complaintResonPopoverVisible,
        "custom-class": "complaint-reason-popover",
        "before-close": _vm.beforeClose,
        "modal-append-to-body": "",
        "destroy-on-close": true,
        "close-on-click-modal": false,
        center: ""
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.complaint,
            "complaintResonPopoverVisible",
            $event
          )
        }
      }
    },
    [
      _vm.loading
        ? _c("div", { staticClass: "no-data" }, [
            _c("i", {
              staticClass: "el-icon-loading",
              staticStyle: { "font-size": "18px", "margin-bottom": "10px" }
            }),
            _c("div", [_vm._v(_vm._s(_vm.$t("loading")) + "...")])
          ])
        : _vm._e(),
      _vm.showNoData
        ? _c("div", { staticClass: "no-data" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/images/class/no-data-img.png")
              }
            }),
            _c("div", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.type == "-200"
                      ? _vm.$t("noPost")
                      : _vm.type == "-900"
                      ? _vm.$t("nogrop")
                      : _vm.$t("noData")
                  ) +
                  "\n    "
              )
            ])
          ])
        : _c("div", [
            _vm.type == -200 && _vm.details
              ? _c("div", [
                  _c("div", { staticClass: "delete-reason-header" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("complaintReason")) +
                        "：" +
                        _vm._s(_vm.details.reportRemarkDescription) +
                        "\n      "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "delete-reason-body" },
                    [
                      _c("div", { staticClass: "delete-reason-user" }, [
                        _c("div", { staticClass: "user-head" }, [
                          _c("img", {
                            staticStyle: { width: "50px", height: "50px" },
                            attrs: { src: _vm.details.profilePhoto, alt: "" }
                          })
                        ]),
                        _c(
                          "div",
                          [
                            _vm.details.vipStatus
                              ? _c(
                                  "span",
                                  { staticClass: "xvipName" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "vipDisplayName",
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.goPersonalCenter(
                                              _vm.details.providerId
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.details.name))]
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.$t("paidMember"),
                                          placement: "bottom",
                                          "popper-class": "me-tooltip-effect"
                                        }
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass: "vipIcon",
                                          attrs: {
                                            src: require("@/assets/images/pay/masonry.png"),
                                            fit: "fill"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.handleClickVip(
                                                _vm.details.providerId
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "user-name",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.goPersonalCenter(
                                        _vm.details.providerId
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.details.name) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            _c("div", { staticClass: "user-time" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.details.publishTime) +
                                  "\n            "
                              )
                            ])
                          ],
                          2
                        )
                      ]),
                      _c("h3", { staticClass: "delete-reason-title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.details.iCanDo) +
                            "\n        "
                        )
                      ]),
                      _vm.details.detail
                        ? _c(
                            "div",
                            _vm._l(JSON.parse(_vm.details.detail), function(
                              item,
                              index
                            ) {
                              return _c("div", { key: index }, [
                                item.key === "web"
                                  ? _c("div", {}, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "details_web",
                                          attrs: {
                                            target: "_black",
                                            href: item.content.url
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "web_img" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: item.content.image,
                                                  alt: ""
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "web_info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "web-title" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        item.content.title
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "web-cont" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        item.content.content
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                item.key === "text"
                                  ? _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(item.content)
                                      }
                                    })
                                  : _vm._e()
                              ])
                            }),
                            0
                          )
                        : _c("div", { staticClass: "delete-reason-content" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.details.detailContent) +
                                "\n        "
                            )
                          ]),
                      !_vm.details.detailContent
                        ? _c("div", {
                            staticClass: "delete-reason-content",
                            domProps: {
                              innerHTML: _vm._s(_vm.details.richDetail)
                            }
                          })
                        : _vm._e(),
                      _vm.details.tags
                        ? _c(
                            "div",
                            { staticClass: "delete-tags" },
                            _vm._l(_vm.details.tags.split(","), function(item) {
                              return _c(
                                "div",
                                { key: item, staticClass: "tag-item" },
                                [
                                  _c("span", {
                                    staticClass:
                                      "iconfont_Me icon-tag2 labelIcon"
                                  }),
                                  _vm._v(_vm._s(item) + "\n          ")
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm.details.attachments.length > 0
                        ? _c(
                            "div",
                            { staticClass: "delete-reason-imgs" },
                            [
                              _vm._l(_vm.details.attachments, function(item) {
                                return _c("img", {
                                  key: item.id,
                                  attrs: { src: item.path, alt: "" }
                                })
                              }),
                              _vm.details.attachments &&
                              _vm.details.attachments[0].type == 1
                                ? _c("video", {
                                    attrs: {
                                      src: _vm.details.attachments[0].path,
                                      controls: ""
                                    }
                                  })
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.details.attachments.length > 0 &&
                      _vm.details.attachments[0].type === 4 &&
                      _vm.details.attachments[0].path !== ""
                        ? _c("FilePreview", {
                            attrs: {
                              fileInfo: {
                                ..._vm.details.attachments[0],
                                progress: 100
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.details.reportManagerStatus == 0 &&
                  _vm.details.isDelete != 3 &&
                  _vm.details.isDelete != 2
                    ? _c(
                        "div",
                        { staticClass: "operation" },
                        [
                          _c("el-button", { on: { click: _vm.handleDelet } }, [
                            _vm._v(_vm._s(_vm.$t("okDelete")))
                          ]),
                          _c("el-button", { on: { click: _vm.handleIgnore } }, [
                            _vm._v(_vm._s(_vm.$t("neglect")))
                          ])
                        ],
                        1
                      )
                    : _c("div", { staticClass: "operation" }, [
                        _c("div", { staticClass: "processed" }, [
                          _vm._v(_vm._s(_vm.$t("processed")))
                        ])
                      ])
                ])
              : _vm._e(),
            _vm.type == -900 && _vm.groupInfo
              ? _c("div", [
                  _c("div", { staticClass: "delete-reason-header" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("complaintReason")) +
                        "：" +
                        _vm._s(_vm.groupInfo.reportRemarkDescription) +
                        "\n      "
                    )
                  ]),
                  _c("div", { staticClass: "delete-reason-body" }, [
                    _c("div", { staticClass: "delete-reason-user" }, [
                      _c("div", { staticClass: "user-head groupInfo" }, [
                        _c("img", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: { src: _vm.groupInfo.group_header, alt: "" }
                        }),
                        _c("span", {
                          staticClass: "iconfont_Me iconStyle icon-open-fill",
                          staticStyle: { color: "rgb(51, 51, 51)" }
                        })
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "user-name" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.groupInfo.group_name) +
                              "\n            "
                          )
                        ]),
                        _c("div", { staticClass: "user-time" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.groupInfo.join_time) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm.groupInfo.reportManagerStatus == 0 &&
                  _vm.groupInfo.is_delete != 3 &&
                  _vm.groupInfo.is_delete != 2
                    ? _c(
                        "div",
                        { staticClass: "operation" },
                        [
                          _c("el-button", { on: { click: _vm.handleDelet } }, [
                            _vm._v(_vm._s(_vm.$t("okDelete")))
                          ]),
                          _c("el-button", { on: { click: _vm.handleIgnore } }, [
                            _vm._v(_vm._s(_vm.$t("neglect")))
                          ])
                        ],
                        1
                      )
                    : _c("div", { staticClass: "operation" }, [
                        _c("div", { staticClass: "processed" }, [
                          _vm._v(_vm._s(_vm.$t("processed")))
                        ])
                      ])
                ])
              : _vm._e(),
            _vm.type == -100 && _vm.userInformation
              ? _c("div", [
                  _c("div", { staticClass: "delete-reason-header" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("complaintReason")) +
                        "：" +
                        _vm._s(
                          _vm.userInformation.report.reportRemarkDescription
                        ) +
                        "\n      "
                    )
                  ]),
                  _c("div", { staticClass: "delete-reason-body" }, [
                    _c("div", { staticClass: "delete-reason-user" }, [
                      _c("div", { staticClass: "user-head groupInfo" }, [
                        _c("img", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            src: _vm.userInformation.profile_photo,
                            alt: ""
                          }
                        })
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "user-name",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goPersonalCenter(
                                  _vm.userInformation.provider_id ||
                                    _vm.userInformation.providerId
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.userInformation.name) +
                                "\n            "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "user-time" }, [
                          _vm._v("ID:" + _vm._s(_vm.userInformation.uc_id))
                        ])
                      ])
                    ]),
                    _vm.userInformation.skills
                      ? _c(
                          "div",
                          {
                            staticClass: "delete-tags",
                            staticStyle: { "margin-top": "10px" }
                          },
                          _vm._l(
                            _vm.userInformation.skills.split(","),
                            function(item) {
                              return _c(
                                "div",
                                { key: item, staticClass: "tag-item" },
                                [
                                  _c("span", {
                                    staticClass:
                                      "iconfont_Me icon-tag2 labelIcon"
                                  }),
                                  _vm._v(_vm._s(item) + "\n          ")
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _c("div", {
                      staticClass: "user-rich",
                      domProps: {
                        innerHTML: _vm._s(_vm.userInformation.rich_intro)
                      }
                    })
                  ]),
                  _vm.userInformation.report.reportManagerStatus == 0 &&
                  _vm.userInformation.report.isDelete != 3 &&
                  _vm.userInformation.report.isDelete != 2
                    ? _c(
                        "div",
                        { staticClass: "operation" },
                        [
                          _c("el-button", { on: { click: _vm.handleDelet } }, [
                            _vm._v(_vm._s(_vm.$t("okDelete")))
                          ]),
                          _c("el-button", { on: { click: _vm.handleIgnore } }, [
                            _vm._v(_vm._s(_vm.$t("neglect")))
                          ])
                        ],
                        1
                      )
                    : _c("div", { staticClass: "operation" }, [
                        _c("div", { staticClass: "processed" }, [
                          _vm._v(_vm._s(_vm.$t("processed")))
                        ])
                      ])
                ])
              : _vm._e(),
            (_vm.type == -901 || _vm.type == -201 || _vm.type == -101) &&
            _vm.groupReplyInfodetails
              ? _c("div", [
                  _c("div", { staticClass: "delete-reason-header" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("complaintReason")) +
                        "：" +
                        _vm._s(
                          _vm.groupReplyInfodetails.reportRemarkDescription
                        ) +
                        "\n      "
                    )
                  ]),
                  _c("div", { staticClass: "delete-reason-body" }, [
                    _vm.complainedUser
                      ? _c("div", { staticClass: "delete-reason-user" }, [
                          _c("div", { staticClass: "user-head groupInfo" }, [
                            _c("img", {
                              staticStyle: { width: "50px", height: "50px" },
                              attrs: {
                                src: _vm.complainedUser.profile_photo,
                                alt: ""
                              }
                            })
                          ]),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "user-name",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.goPersonalCenter(
                                      _vm.complainedUser.provider_id
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.complainedUser.name) +
                                    "\n            "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "user-time" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.groupReplyInfodetails.join_time ||
                                      _vm.groupReplyInfodetails.reviewDatetime
                                  ) +
                                  "\n            "
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _c("div", {
                      staticStyle: {
                        "margin-top": "10px",
                        "white-space": "pre-wrap",
                        "line-height": "24px",
                        "max-height": "168px",
                        "overflow-x": "hidden",
                        "overflow-y": "auto"
                      },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$emoReplaceToImage(
                            _vm.hyperlinksCheck(
                              _vm.groupReplyInfodetails.content
                            )
                          )
                        )
                      }
                    }),
                    _vm.groupReplyInfodetails.imgUrl &&
                    _vm.groupReplyInfodetails.imgUrl != ""
                      ? _c(
                          "div",
                          { style: { height: "100px", "margin-top": "10px" } },
                          [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100%" },
                              attrs: {
                                src: _vm.groupReplyInfodetails.imgUrl,
                                fit: "cover"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm.groupReplyInfodetails.reportManagerStatus == 0 &&
                  _vm.groupReplyInfodetails.isDelete != 3 &&
                  _vm.groupReplyInfodetails.isDelete != 2
                    ? _c(
                        "div",
                        { staticClass: "operation" },
                        [
                          _c("el-button", { on: { click: _vm.handleDelet } }, [
                            _vm._v(_vm._s(_vm.$t("okDelete")))
                          ]),
                          _c("el-button", { on: { click: _vm.handleIgnore } }, [
                            _vm._v(_vm._s(_vm.$t("neglect")))
                          ])
                        ],
                        1
                      )
                    : _c("div", { staticClass: "operation" }, [
                        _c("div", { staticClass: "processed" }, [
                          _vm._v(_vm._s(_vm.$t("processed")))
                        ])
                      ])
                ])
              : _vm._e()
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }